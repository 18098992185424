@import "../../styles/variables";

.billPayWarningOuterContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.billPayWarningInnerContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 5%;
    margin-left: 5%;

    background-color: white;
    padding: 20px;
    border: 2px #bf0000 solid;
    border-left: 10px #bf0000 solid;
    border-radius: 10px;
}

.billMaintenanceHeaderContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.alertHeaderIcon {
    font-size: 40px;
    margin-right: 20px;
    color: #bf0000;
}

#billingMaintenanceHeader {
    word-break: break-word;
    hyphens: auto;
    font-family: 'Lato' !important;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    color: #bf0000;
}

.billingMaintenanceText {
    word-break: break-word;
    hyphens: auto;
    font-family: 'Lato' !important;
    font-size: 16px;
}