@import '../../styles/variables';

.button {
    font-family: 'Avenir' !important;
    font-size: 16px !important;
    margin-bottom: 15px;
    max-height: 39px;
    line-height: initial !important;
    flex: 1;
    padding: 7px 1.5em !important;
    margin-right: 0px !important;
}

.biggerButtonFont {
    font-family: 'Lato' !important;
    font-size: 18px !important;
    margin-bottom: 15px;
    max-height: 50px;
    line-height: initial !important;
    flex: 1;
    padding: 11px 1.5em !important;
    margin-right: 0px !important;
}

.payBillButton {
    font-family: 'Lato' !important;
    font-size: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 30px !important;
    width: 114px !important;
}

.longerPayBillButton {
    font-family: 'Lato' !important;
    font-size: 14px !important;
    padding-top: 12px !important;
    padding-bottom: 11px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 30px !important;
}

.blue {
    background-color: $link-blue !important;
    color: white !important;

    &:hover {
        background-color: $view-action-blue !important;
        
    }

    &:active {
        background-color: $primary-blue!important;
    }
}

.midnightBlue {
    background-color: #003A88 !important;
    color: white !important;

    &:hover {
        background-color: #003375 !important;
        
    }

    &:active {
        background-color: #003375 !important;
    }
}

.white {
    background-color: white !important;
    color: #003A88 !important;
    border: #003A88 1px solid !important;

    &:hover {
        background-color: #f0f0f0 !important;
        
    }

    &:active {
        background-color: #f0f0f0 !important;
    }
}

.whiteUnavailable {
    background-color: white !important;
    color: #003A88 !important;
    border: #003A88 1px solid !important;
    padding: 10px !important;
    width: 180px !important;

    &:hover {
        background-color: #f0f0f0 !important;
    }

    &:active {
        background-color: #f0f0f0 !important;
    }
}

.green {
    background-color: $circle-green !important;
    color: white !important;

    &:hover {
        background-color: $green-hover !important;
        
    }

    &:active {
        background-color: $green-active!important;
    }
}

.green {
    background-color: $circle-green !important;
    color: white !important;

    &:hover {
        background-color: $green-hover !important;
        
    }

    &:active {
        background-color: $green-active!important;
    }
}

.vibrantGreen {
    background-color: $circle-green !important;
    color: white !important;

    &:hover {
        background-color: #108231 !important;
        
    }

    &:active {
        background-color: #129A38 !important;
    }
}

.yellow {
    background-color: #d89b22 !important;
    color: white !important;

    &:hover {
        background-color: #d39314 !important;
        
    }

    &:active {
        background-color: #d39314 !important;
    }
}

.red {
    background-color: $danger-red !important;
    color: white !important;

    &:hover {
        background-color: $danger-red-hover !important;
        
    }

    &:active {
        background-color: $danger-red-active!important;
    }
}

.grey {
    background-color: $nav-menu-dropdown !important;
    color: $link-dark-grey !important;

    &:hover {
        background-color: $primary-grey-hover !important;
    }

    &:active {
        background-color: $primary-grey !important;
    }
}

.lightBlue {
    background-color: $light-blue !important;
    color: white !important;

    &:hover {
        background-color: $light-blue-hover !important;
        
    }

    &:active {
        background-color: $light-blue-active !important;
    }
}

.darkBlue {
    background-color: $dark-blue !important;
    color: white !important;

    &:hover {
        background-color: $dark-blue-hover !important;
        
    }

    &:active {
        background-color: $dark-blue-active !important;
    }
}

.orange {
    background-color: $orange !important;
    color: white !important;

    &:hover {
        background-color: $orange-hover !important;
        
    }

    &:active {
        background-color: $orange-active !important;
    }
}

.black {
    background-color: $primary-black !important;
    color: white !important;

    &:hover {
        background-color: $black-hover !important;
        
    }

    &:active {
        background-color: $black-active !important;
    }
}

.underButtonText {
    margin-top: 10px;
    font-size: 14px;
    color: $danger-red;
}
