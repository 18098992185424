@import '../../styles/variables.scss';

.headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    color: #302E2E;
    font: {
        family: Dienstag;
        size: 24px;
    }
}

.action_container {
    background-color: #FDFDFD;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font: {
        family: Avenir;
        spacing: 0px;
    }
    margin: 10px 0px 30px 0px;
    padding: 20px;
}

.action_container_heading {
    color: #222222;
    display: inline-block;
    margin-right: 30px;
    text-transform: capitalize;
    font: {
        family: 'Lato' !important;
        size: 23px;
        weight: 300;
    }
}

.listing_table {
    margin-top: 30px;
}

.item_text {
    display: inline-block;
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin: 20px;
}

/* start table style */

.active {
    text-align: center;
    width: 7%;
    margin-right: 5px;

    .greenCheckmark {
        color: green;
    }
    .redX {
        color: red;
    }
}

.tag {
    display: inline-block;
    border-radius: 4px;
    color: white;
    width: 150px;
    height: 26px;
    padding: 4px 0px;
    text: {
        align: center;
        transform: capitalize
    }
    font: {
        size: 14px;
        weight: bold;
    }
}

.tableContainer {
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Lato' !important;
}

.tableHeader {
    height: 50px;
    font-size: 16px;
    padding: 0px 14px;
    font-weight: bold;
    text-align: left;
}

.item {
    font-size: 16px;
    color: inherit;
}

td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.actions {
    text-align: center;
    width: 10%;
    
    i {
        margin: 5px;
    }
}

.editIcon {
    color: $link-blue;
    cursor: pointer;
}

.trashIcon {
    color: red;
    cursor: pointer;
}

.timestamp {
    width: 25%;
}

.billMaintenanceText {
    width: 30%;
    padding-right: 20px;
    word-break: break-word;
}

.alertText {
    width: 35%;
    padding-right: 20px;
    word-break: break-word;
}

.alertHeader {
    font-size: 20px;
    font-weight: bold;
}

/* end table style */

/* start modal style */

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.buttonWrapper {
    margin-top: 20px;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.modalContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    justify-content: space-evenly;
    word-wrap: break-word;

    & > div {
        width: 100%;
    }
}

.modalHeader {
    word-wrap: break-word;
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}

.submitContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
    gap: 20px !important;
}

.buttonRight {
    margin-left: auto;
}

.formContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.toggleContainer {
    margin-bottom: 20px;
}

.textEditorContainer {
    margin-bottom: 20px;
}

.titleContainer {
    width: 100%;
}

.alertDeleteModalBody {
    font-size: 17px;
}

/* end modal style */

/* start bill pay maintenance styles */

.title {
    font-family: 'Lato';
    font-size: 24px;
    margin-bottom: 30px;
}

.backToSysAlertsArrowContainer {
    width: fit-content;
    margin-bottom: 50px;
}

.billPayMaintenanceSettingsContainer {
    display: flex;
    justify-content: space-between;
}

.currentBillPayStatus {
    margin-bottom: 20px;
    margin-top: 10px !important;
    font-family: 'Lato' !important;
}

.timeSettingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hourContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.hourText {
    font-family: 'Lato';
    margin-right: 20px;
    font-size: 16px;
    margin-bottom: 0px;
}

.scheduleHeaderText {
    margin-bottom: 10px;
}

.timeInputField {
    width: 80px;
}

.timeIntervalContainer {
    display: flex;
}

.closeButtonDialog {
    font-size: 25px;
    cursor: pointer;
}

.enabledText {
    color: green;
}

.disabledText {
    color: $danger-red;
}

.datepicker {
    width: 200px;
    padding: 10px;
    border: 1px solid #dedede;
    border-radius: 5px;
    background-color: white;
    font-family: 'Lato' !important;
}

.datepicker:focus {
    border-color: #85b7d9;
    outline: none;
}
